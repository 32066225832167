
import { Component, Watch, Prop } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import { Therapist } from "@/interfaces";
import { Route } from "vue-router";
import vSelect from "vue-select";
import "@/scss/vue-select.css";
import { TranslateResult } from "vue-i18n";
import { get, patch, post } from "@/api";
import i18n from "@/i18n";
const pageKey = "personal";

Component.registerHooks(["beforeRouteLeave"]);

interface Fields {
  [key: string]: any;
  first_name: null | string;
  last_name: null | string;
  email: null | string;
  phone_number: null | string;
  title: null | string;
  cities: string[];
  country: null | string;
  description: null | string;
  languages: string[];
}

@Component({
  components: {
    vSelect,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class InfoPersonalComponent extends Vue {
  image: File | null = null;
  oldUrl: string | null = null;
  url: string | null = null;
  isDataFilledCorrect = true;

  fields: Fields = {
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    title: null,
    cities: [],
    country: null,
    description: null,
    languages: [],
  };

  old_phone_number: null | string = null;
  languages: Record<string, TranslateResult | string>[] = [];
  countries: Record<string, TranslateResult | string>[] = [];

  @Prop({
    default: function() {
      return null;
    },
  })
  therapist!: Therapist.TherapistInfo | null;

  @Prop()
  firstTime!: boolean;

  created() {
    this.getPhoto();
    this.getLanguagesAndCountries();
    this.getTherapistData();
  }

  async getPhoto() {
    try {
      console.log("AAAA");
      const { data: photoPath } = await get.therapistPhotoPath();
      if (photoPath.photo) {
        this.url = process.env.VUE_APP_TARGET + photoPath.photo;
        this.oldUrl = process.env.VUE_APP_TARGET + photoPath.photo;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getLanguagesAndCountries() {
    try {
      const { data: dictionaries } = await get.dict();
      if (dictionaries && this.languages.length == 0)
        [...dictionaries["languages"]].forEach((lang) =>
          this.languages.push({
            name: this.$t("languages." + lang),
            code: lang,
          })
        );
      if (dictionaries && this.countries.length == 0)
        [...dictionaries["countries"]].forEach((lang) =>
          this.countries.push({
            name: this.$t("countries." + lang),
            code: lang,
          })
        );
    } catch (err) {
      console.log(err);
    }
  }

  async getTherapistData() {
    if (this.therapist) {
      this.fields.first_name = this.therapist.user.first_name;
      this.fields.last_name = this.therapist.user.last_name;
      this.fields.description = this.therapist.description;
      this.fields.cities = [...this.therapist.cities];
      this.fields.country = this.therapist.country;
      this.fields.title = this.therapist.title;
      this.fields.languages = [...this.therapist.languages];
    }
    const { data: userInfo } = await get.user();
    if (userInfo) {
      this.fields.phone_number = userInfo.phone_number;
      this.old_phone_number = userInfo.phone_number;
      this.fields.email = userInfo.email;
    }
  }

  get isDataFilled() {
    const keys = Object.keys(this.fields);
    const emptyFields = keys.filter(
      (key) =>
        (!this.fields[key] || this.fields[key].toString().trim() === "") &&
        key !== "title" &&
        key !== "email" &&
        key !== "description" &&
        key !== "languages"
    );
    const inLangEmpty = this.fields.languages.length === 0;
    return emptyFields.length === 0 && !inLangEmpty;
  }

  get changedFields() {
    const keys = Object.keys(this.fields);
    const changedFields = keys.filter((key) => {
      if (this.therapist) {
        if (key === "first_name" || key === "last_name")
          return this.fields[key] !== this.therapist.user[key];
        else if (key === "phone_number")
          return this.fields[key] !== this.old_phone_number;
        else if (key === "cities")
          return this.fields.cities.sort().toString() !== this.therapist.cities.sort().toString();
        else if (key === "languages") {
          return (
            this.therapist.languages.sort().toString() !==
            this.fields.languages.sort().toString()
          );
        }
        else if (key !== "email") return this.fields[key] !== this.therapist[key];
      }
    });
    if (this.image && this.url!==this.oldUrl) changedFields.push("image");
    return changedFields;
  }

  onImageChange() {
    this.image = (this.$refs.file as Vue & { files: File[] }).files[0];
    if (this.image) {
      this.url = URL.createObjectURL(this.image);
    }
  }

  setIsChecked(lang: Record<string, TranslateResult | string>) {
    if (this.fields.languages.indexOf(lang.code.toString()) !== -1) {
      return require(`@/assets/checked.png`);
    } else {
      return require(`@/assets/unchecked.png`);
    }
  }

  @Watch("image")
  onFileChange() {
    if (this.image) {
      this.url = URL.createObjectURL(this.image);
    } else {
      this.url = this.oldUrl;
    }
  }

  cancel() {
    this.fields = {
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      title: null,
      cities: [],
      country: null,
      description: null,
      languages: [],
    };
    this.isDataFilledCorrect = true;
    this.image = null;
    this.getTherapistData();
  }

  async save() {
    if (this.isDataFilled) {
      await this.submit();
      await this.getTherapistData();
    } else {
      this.isDataFilledCorrect = false;
    }
  }

  async submit() {
    try {
      if (this.changedFields.length !== 0) {
        const therapistData: Record<string, string | string[] | null> = {};
        const personalData: Record<string, string | string[] | null> = {};
        this.changedFields.forEach((field: string) => {
          if (field === "languages")
            therapistData[field] = [...this.fields.languages];
          else if (field === "cities")
            therapistData[field] = [...this.fields.cities];
          else if (
            field !== "first_name" &&
            field !== "last_name" &&
            field !== "email" &&
            field !== "phone_number"
          ) {
            therapistData[field] = this.fields[field];
          } else {
            personalData[field] = this.fields[field];
          }
        });
        let therapist = JSON.parse(JSON.stringify(this.therapist));
        if (this.changedFields.indexOf("image") !== -1 && this.image) {
          try {
            await post.therapistPhoto(this.image);
          } catch (error) {
            window.alert(
              "Sorry, we can't upload your photo. Please, try again."
            );
          }
        }
        if (Object.keys(therapistData).length !== 0) {
          const { data } = await patch.therapistInfo(therapistData);
          therapist = data;
        console.log(data);
        }
        if (Object.keys(personalData).length !== 0) {
          const { data: userInfo } = await patch.therapistUserInfo(
            personalData
          );
          if (therapist) {
            therapist.user.first_name = userInfo.first_name;
            therapist.user.last_name = userInfo.last_name;
          }
        }
        this.old_phone_number = this.fields.phone_number
        this.oldUrl = this.url;
        this.isDataFilledCorrect = true;
        if (this.firstTime && therapist) {
          this.$store.commit("setStage", therapist.completed_fillings);
          console.log(therapist);
        }
        if (!this.firstTime) this.$openModal({ component: "SavedSuccessfullyModal" });
        this.$emit("update:therapist", therapist);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async beforeRouteLeave(to: Route, from: Route, next: any) {
    if (this.firstTime && to.meta.requiresAuth) {
      if (this.isDataFilled) {
        await this.submit();
        next();
      }
      this.isDataFilledCorrect = false;
    } else if (this.changedFields.length === 0 || !this.$store.state.user) {
      next();
    } else {
      this.$openModal({
        component: "InfoFillingModal",
        props: {
          next: () => next(),
          save: () => this.save(),
          cancel: () => this.cancel(),
          isDataFilled: this.isDataFilled,
          firstTime: this.firstTime
        },
        this: this,
      });
    }
  }
}
