var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('div',{staticClass:"fields"},[_c('div',{staticClass:"filling"},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"image-paste"},[_c('div',{staticClass:"image-wrapper"},[(_vm.url)?_c('img',{attrs:{"src":_vm.url}}):_vm._e()]),_c('div',{staticClass:"upload-button"},[_c('input',{ref:"file",attrs:{"type":"file","id":"assetsFieldHandle","accept":".jpg,.jpeg,.png"},on:{"change":_vm.onImageChange}}),_c('div',[_c('label',{staticClass:"upload form-element",attrs:{"for":"assetsFieldHandle"}},[_vm._v(_vm._s(_vm.$t("information-filling.upload")))])])])]),_c('div',{staticClass:"inputs"},[_c('div',{staticClass:"two-in-row"},[_c('label',{class:{
                incorrect:
                  !_vm.isDataFilledCorrect &&
                  (!_vm.fields.first_name ||
                    _vm.fields.first_name.toString().trim() === ''),
              },attrs:{"for":"first_name"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("for-specialists.join-calmsie.forms.first_name"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s("*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.first_name),expression:"fields.first_name"}],attrs:{"id":"first_name","type":"text"},domProps:{"value":(_vm.fields.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "first_name", $event.target.value)}}})]),_c('label',{class:{
                incorrect:
                  !_vm.isDataFilledCorrect &&
                  (!_vm.fields.last_name ||
                    _vm.fields.last_name.toString().trim() === ''),
              },attrs:{"for":"last_name"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("for-specialists.join-calmsie.forms.last_name"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s("*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.last_name),expression:"fields.last_name"}],attrs:{"id":"last_name","type":"text"},domProps:{"value":(_vm.fields.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "last_name", $event.target.value)}}})])]),_c('div',{staticClass:"two-in-row"},[_c('div',{staticClass:"email"},[_c('h5',[_vm._v(_vm._s(_vm.$t("for-specialists.join-calmsie.forms.email")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.email),expression:"fields.email"}],attrs:{"type":"email","disabled":""},domProps:{"value":(_vm.fields.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "email", $event.target.value)}}})]),_c('label',{class:{
                incorrect:
                  !_vm.isDataFilledCorrect &&
                  (!_vm.fields.phone_number ||
                    _vm.fields.phone_number.toString().trim() === ''),
              },attrs:{"for":"phone"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("for-specialists.join-calmsie.forms.phone"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s("*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.phone_number),expression:"fields.phone_number"}],attrs:{"id":"phone","type":"text"},domProps:{"value":(_vm.fields.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "phone_number", $event.target.value)}}})])]),_c('div',{staticClass:"two-in-row"},[_c('v-select',{staticClass:"info-filling-select",class:{ incorrect: !_vm.isDataFilledCorrect && !_vm.fields.country },attrs:{"options":_vm.countries,"label":"name","reduce":function (country) { return country.code; },"placeholder":_vm.$t('information-filling.country'),"transition":".1s","clearable":false,"searchable":false},model:{value:(_vm.fields.country),callback:function ($$v) {_vm.$set(_vm.fields, "country", $$v)},expression:"fields.country"}}),_c('label',{class:{
                incorrect:
                  !_vm.isDataFilledCorrect &&
                  (!_vm.fields.cities[0] || _vm.fields.cities[0].toString().trim() === ''),
              },attrs:{"for":"city"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.city"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s("*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.cities[0]),expression:"fields.cities[0]"}],attrs:{"id":"city","type":"text"},domProps:{"value":(_vm.fields.cities[0])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields.cities, 0, $event.target.value)}}})])],1),_c('div',{staticClass:"component"},[_c('label',{staticClass:"component-input",attrs:{"for":"title"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("information-filling.title")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.title),expression:"fields.title"}],attrs:{"id":"title","type":"text"},domProps:{"value":(_vm.fields.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "title", $event.target.value)}}})])])])]),_c('div',{staticClass:"bottom-section"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.description),expression:"fields.description"}],attrs:{"placeholder":"Write something about yourself here... Let your patients get to know you!"},domProps:{"value":(_vm.fields.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "description", $event.target.value)}}})])]),_c('h3',{staticClass:"checkbox-title"},[_vm._v(" "+_vm._s(_vm.$t("information-filling.languages"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s("*"))])]),_c('div',{staticClass:"checkbox"},[(_vm.languages)?_c('div',{staticClass:"box",class:{
          incorrect: !_vm.isDataFilledCorrect && _vm.fields.languages.length === 0,
        }},_vm._l((_vm.languages),function(lang,index){return _c('div',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.languages),expression:"fields.languages"}],attrs:{"type":"checkbox","id":'lang' + index},domProps:{"value":lang.code,"checked":Array.isArray(_vm.fields.languages)?_vm._i(_vm.fields.languages,lang.code)>-1:(_vm.fields.languages)},on:{"change":function($event){var $$a=_vm.fields.languages,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=lang.code,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fields, "languages", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fields, "languages", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fields, "languages", $$c)}}}}),_c('label',{attrs:{"for":'lang' + index}},[_c('img',{staticClass:"check",attrs:{"src":_vm.setIsChecked(lang)}}),_c('h6',[_vm._v(_vm._s(lang.name))])])])}),0):_c('div',{staticClass:"box"},[_c('spinner')],1)])]),(_vm.firstTime)?_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"button disabled"},[_vm._v(_vm._s(_vm.$t("information-filling.back")))]),_c('router-link',{staticClass:"button",attrs:{"to":"/dashboard/myprofile/background"}},[_vm._v(_vm._s(_vm.$t("information-filling.continue")))])],1):_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button",on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("information-filling.cancel"))+" ")]),_c('button',{staticClass:"button",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("information-filling.save"))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }